@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i");
@keyframes show-label {
  0% {
    transform: translateY(-2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoom-in-reveal {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom-out-reveal {
  0% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-to-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: translateX(-2rem);
    opacity: 0;
  }
}

@keyframes fadein-to-left {
  0% {
    transform: translateX(2rem);
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-to-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: translateX(2rem);
    opacity: 0;
  }
}

* {
  font-size: inherit;
  font-weight: 400;
  font-weight: inherit;
  font-style: inherit;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:focus {
  outline: 0;
}

i {
  font-style: italic;
}

html {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #FFF;
}

body {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #7256E3;
}

body.-invertedbg {
  background: #FFF;
}

body.-invertedbg .page-content.-active svg path {
  fill: #7256E3;
}

body.-invertedbg .text-box .text {
  color: #FFF;
}

body.-animating, body.-processing {
  pointer-events: none;
}

body.-article {
  overflow-y: auto;
  overflow-y: overlay;
}

body.-moveright > main,
body.-moveright .nav-bar {
  transform: translateX(100%);
}

@media (min-width: 961px) {
  body.-moveright > main,
  body.-moveright .nav-bar {
    transform: translateX(30rem);
  }
}

body.-moveleft > main,
body.-moveleft .nav-bar {
  transform: translateX(-100%);
}

@media (min-width: 961px) {
  body.-moveleft > main,
  body.-moveleft .nav-bar {
    transform: translateX(-30rem);
  }
}

main {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  transition: transform cubic-bezier(0.55, 0, 0.1, 1) 0.5s;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  transition: mask-image 0.35s cubic-bezier(0.55, 0, 0.1, 1);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 45px, black calc(100% - 45px), rgba(0, 0, 0, 0) 100%);
}

input {
  font-family: 'Roboto', sans-serif;
}

input[type='checkbox'] {
  appearance: checkbox;
}

.-highlight {
  padding: 2px 4px;
  transition: all 0.2s cubic-bezier(0.19, 0.01, 0.03, 1.01);
  text-decoration: none;
  color: #FFF;
  background-color: #9951F5;
}

.speeches {
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}

.back._hidden {
  display: none;
}

.nav-bar {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: space-between;
  height: 55px;
  padding-right: 8vw;
  padding-left: 8vw;
  transition: transform cubic-bezier(0.55, 0, 0.1, 1) 0.5s;
  color: #FFF;
}

.nav-bar.-article {
  position: fixed;
  z-index: 1;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.3);
}

.nav-bar > .logo.-hide {
  display: none;
}

.nav-bar > .menu {
  flex: 0 0 auto;
  width: 44px;
  height: 44px;
  margin-right: -9px;
  cursor: pointer;
  background-image: url("../img/menu-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.nav-bar > .menu.-hide {
  display: none;
}

.nav-bar a {
  text-decoration: none;
}

.nav-bar > .info {
  display: flex;
  align-items: center;
}

.nav-bar > .info.-hide {
  display: none;
}

.nav-bar > .info > .back {
  position: relative;
  z-index: 80;
  width: 44px;
  height: 44px;
  margin-right: 4px;
  margin-left: -11px;
  cursor: pointer;
  background-image: url("../img/arrow-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.nav-bar > .info > .back.-rotate {
  transform: rotate(-90deg);
}

.nav-bar > .info > .breadcrumb {
  font-size: 16px;
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

.nav-bar > .info > .breadcrumb > .title {
  font-size: 16px;
  font-weight: 500;
}

.nav-bar > .info > .breadcrumb > .name {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
}

.nav-bar > .filter {
  width: 44px;
  height: 44px;
  margin-left: -9px;
  cursor: pointer;
  background-image: url("../img/gear-white.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.nav-bar > .filter.-hide {
  display: none;
}

.nav-bar.-negative {
  color: #000;
}

.nav-bar.-negative > .menu {
  background-image: url("../img/menu-black.svg");
}

.nav-bar.-negative > .info > .back {
  background-image: url("../img/arrow-black.svg");
}

.app-menu {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: transform cubic-bezier(0.55, 0, 0.1, 1) 0.5s;
  transform: translateX(105%);
  color: #000;
  background-color: #FFF;
  box-shadow: -3px 0 10px -5px rgba(0, 0, 0, 0.3);
}

@media (min-width: 961px) {
  .app-menu {
    width: 30rem;
  }
}

.app-menu.-active {
  transform: translate(0);
}

.app-menu > .links {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 55px;
}

.app-menu > .links > a {
  font-size: 24px;
  font-weight: 600;
  line-height: 2.5;
  color: #7256E3;
}

.app-menu > .nav {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: space-between;
  height: 55px;
  padding: 0 8vw;
}

@media (min-width: 961px) {
  .app-menu > .nav {
    padding: 0 2.5rem;
  }
}

.app-menu > .nav > a {
  color: #000;
}

.app-menu > .nav > .close {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: -10px;
  padding: 10px;
  cursor: pointer;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.app-menu > .nav > .title {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}

.filter-modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: transform cubic-bezier(0.55, 0, 0.1, 1) 0.5s;
  transform: translateX(-105%);
  color: #000;
  background-color: #FFF;
  box-shadow: 3px 0 10px -5px rgba(0, 0, 0, 0.3);
}

@media (min-width: 961px) {
  .filter-modal {
    width: 30rem;
  }
}

.filter-modal.-active {
  transform: translate(0);
}

.filter-modal > .nav {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: space-between;
  height: 55px;
  padding: 0 8vw;
}

@media (min-width: 961px) {
  .filter-modal > .nav {
    padding: 0 2.5rem;
  }
}

.filter-modal > .content {
  display: flex;
  overflow: auto;
  overflow: overlay;
  flex-direction: column;
  padding-bottom: 5rem;
}

.filter-modal > .content > label {
  font-size: 12px;
  font-weight: bold;
  margin-top: 30px;
  text-transform: uppercase;
  color: #000;
}

.filter-modal > .content > input {
  font-size: 14px;
  width: 100%;
  margin-top: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: all cubic-bezier(0.55, 0, 0.1, 1) 0.25s;
  color: #000;
  border: none;
  border-bottom: 1px solid #000;
  background: none;
}

.filter-modal > .content > input:focus {
  transition: none;
  color: #7256E3;
  border-color: #7256E3;
  outline: none;
}

.filter-modal > .content > .hint {
  font-size: 12px;
  margin-top: 5px;
}

.filter-modal > .content > .footer {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5rem;
  background-color: #FFF;
  box-shadow: 0px -3px 10px -5px rgba(0, 0, 0, 0.3);
}

.filter-modal > .content > .footer > .button {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: 10px;
  text-decoration: none;
  color: #FFF;
  background-color: #7256E3;
}

.filter-modal > .content > .footer > .button:hover {
  background-color: #846ce7;
}

.filter-modal > .nav > a {
  color: #000;
}

.filter-modal > .nav > .close {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: -10px;
  padding: 10px;
  cursor: pointer;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.filter-modal > .nav > .title {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}

.filter-dropdown {
  display: flex;
  flex-direction: column;
}

.filter-dropdown > button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px 8vw;
  cursor: pointer;
}

@media (min-width: 961px) {
  .filter-dropdown > button {
    padding: 25px 2.5rem;
  }
}

.filter-dropdown > button > .icon {
  margin-right: 25px;
}

.filter-dropdown > button > .arrow {
  margin-left: auto;
  transition: transform .3s ease;
  transform: rotate(90deg);
}

.-closed.filter-dropdown > button > .arrow {
  transform: rotate(0deg);
}

.filter-dropdown > button > .content {
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.filter-dropdown > button > .content > .name {
  font-size: 18px;
  font-weight: 500;
}

.filter-dropdown > button > .content > .selected {
  font-size: 12px;
  color: #7256E3;
}

.filter-dropdown > button > .content > .-fadein {
  animation: zoom-out-reveal 0.2s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}

.filter-dropdown > button > .content > .-fadeout {
  animation: fade-to-left 0.2s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}

.filter-select {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 315px;
  padding: 30px 8vw;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  background-color: #F7F7F7;
}

@media (min-width: 961px) {
  .filter-select {
    padding: 30px 2.5rem;
  }
}

.filter-dropdown.-closed .filter-select {
  height: 0;
  padding: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

.filter-select > .options {
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
  max-width: 30ch;
}

.filter-select > .options > .option {
  position: absolute;
  display: none;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  opacity: 0;
}

.filter-select > .options > .option.-active {
  display: flex;
  animation: zoom-in-reveal .3s ease forwards;
}

.filter-select > .options > .option.-fadeleft {
  animation: fade-to-left .3s ease forwards;
}

.filter-select > .options > .option.-faderight {
  animation: fade-to-right .3s ease forwards;
}

.filter-select > .options > .option > .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 175px;
}

.filter-select > .options > .option > .description {
  font-size: 14px;
  max-width: 30ch;
  text-align: center;
}

.filter-select > .options > .option > .label {
  font-size: 18px;
  white-space: pre-wrap;
}

.filter-select > .arrow {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.filter-select > .arrow::after {
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  background-image: url("../img/pip-arrow.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.filter-select > .arrow.-left {
  margin-left: -15px;
}

.filter-select > .arrow.-right {
  margin-right: -15px;
}

.filter-select > .arrow.-left::after {
  transform: scaleX(-1);
}

.filter-select > .arrow.-left:hover::after {
  transform: scaleX(-1) translateX(7px);
}

.filter-select > .arrow.-right:hover::after {
  transform: translateX(7px);
}

.filter-select > .indicators {
  position: absolute;
  bottom: 20px;
  display: flex;
}

.filter-select > .indicators > .indicator {
  display: flex;
  width: 5px;
  height: 5px;
  margin: 0 3px;
  transition: background-color .15s ease;
  border: 3px solid #7256E3;
  border-radius: 50%;
}

.filter-select > .indicators > .indicator.-active {
  background-color: #7256E3;
}

.text-article {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  margin-top: 55px;
  margin-bottom: 3em;
}

.text-article > .video {
  display: block;
  flex: 0 0 auto;
  width: 100%;
  height: calc(100vh - 185px);
}

@media (min-width: 641px) {
  .text-article > .video {
    height: calc(100vh - 235px);
  }
}

.text-article > .text {
  font-size: 14px;
  max-width: 66ch;
  margin: 0 8vw;
}

@media (min-width: 641px) {
  .text-article > .text {
    font-size: 20px;
  }
}

.text-article > .text > h1 {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 1.75em;
  color: black;
}

@media (min-width: 641px) {
  .text-article > .text > h1 {
    font-size: 36px;
  }
}

.text-article > .text > h2 {
  font-size: 18px;
  line-height: 1.25;
  margin-top: .5em;
  margin-bottom: 1.75em;
  color: gray;
}

@media (min-width: 641px) {
  .text-article > .text > h2 {
    font-size: 22px;
  }
}

.text-article > .text > p {
  font-size: 14px;
  line-height: 1.8;
  color: #000;
}

@media (min-width: 641px) {
  .text-article > .text > p {
    font-size: 20px;
  }
}

.text-article > .text > p + p {
  margin-top: 2em;
}

.text-article > .text > p > a {
  font-weight: inherit;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #7256E3;
  background-image: linear-gradient(180deg, transparent 65%, rgba(114, 86, 227, 0.3) 0);
}

@media (min-width: 961px) {
  .text-article > .text > p > a {
    background-size: 0 100%;
  }
  .text-article > .text > p > a:hover {
    background-size: 100% 100%;
  }
}

.form-select {
  font-weight: 600;
  margin-top: 4px;
  padding-top: 6px;
  padding-right: 21px;
  padding-bottom: 6px;
  color: #7256E3;
  border-bottom: 1px solid #000;
  background-image: url("../img/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: 100% 58%;
  background-size: 16px;
}

.form-select > option:disabled {
  color: transparent;
  background-color: transparent;
}

.reveal-circle {
  position: absolute;
  z-index: 200;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.reveal-circle > g > .circle {
  transform: scale(0) translateZ(0);
  transform-origin: 1px 1px;
}

.reveal-circle > g > .circle.-invertedbg {
  fill: #7256E3;
}

.reveal-circle > g > .circle.-animating {
  opacity: 1;
  transition: transform 0.65s cubic-bezier(0.55, 0, 0.1, 1);
}

.reveal-circle > g > .circle.-reverse {
  opacity: 0;
}

.reveal-circle > g > .circle.-fadeout {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.reveal-circle > g > .circle.-fadein {
  opacity: 1;
  transition: opacity 0.4s ease-in-out, transform 0.65s cubic-bezier(0.55, 0, 0.1, 1);
}

.text-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.text-box > .text {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

.manifestation-page {
  position: fixed;
  z-index: 80;
  top: 0;
  display: flex;
  overflow: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateY(100%);
  color: #000;
  background-color: #FFF;
}

.manifestation-page.-open {
  transform: translateY(0);
}

.manifestation-page > .content {
  display: flex;
  overflow-y: auto;
  overflow-y: overlay;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 8vw 0 8vw;
  -webkit-overflow-scrolling: touch;
}

.manifestation-page > .content > .text {
  font-size: 14px;
  max-width: 66ch;
  height: 100%;
  padding-top: 55px;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text {
    font-size: 20px;
  }
}

.manifestation-page > .content > .text > h1 {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 1.75em;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > h1 {
    font-size: 36px;
  }
}

.manifestation-page > .content > .text > h2 {
  font-size: 16px;
  line-height: 1.25;
  margin-top: .25em;
  margin-bottom: 1.75em;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > h2 {
    font-size: 22px;
  }
}

.manifestation-page > .content > .text > .summary {
  font-size: 16px;
  line-height: 1.5;
  margin-top: .5em;
  margin-bottom: 1.75em;
  color: gray;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .summary {
    font-size: 22px;
  }
}

.manifestation-page > .content > .text > .separator {
  font-size: 16px;
  height: 20px;
  margin-bottom: 1.75em;
  background-image: url("../img/speech-separator.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .separator {
    font-size: 22px;
  }
}

.manifestation-page > .content > .text > .speech span {
  font-size: 14px !important;
  line-height: 1.8 !important;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .speech span {
    font-size: 20px !important;
  }
}

.manifestation-page > .content > .text > .speech p {
  font-size: 14px;
  margin-top: 2em;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .speech p {
    font-size: 20px;
  }
}

.manifestation-page > .content > .text > .endseparator {
  height: 20px;
  margin-bottom: 1.75em;
  background-image: url("../img/speech-separator-end.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.manifestation-page > .content > .text > .info > span {
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .info > span {
    font-size: 22px;
  }
}

.manifestation-page > .content > .text > .info > p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: .2em;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .info > p {
    font-size: 20px;
  }
}

.manifestation-page > .content > .text > .info {
  font-size: 14px;
  margin-top: 1.5em;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .info {
    font-size: 20px;
  }
}

.manifestation-page > .content > .text > .spacer {
  font-size: 14px;
  padding-bottom: 2.5em;
}

@media (min-width: 641px) {
  .manifestation-page > .content > .text > .spacer {
    font-size: 20px;
  }
}

.hex-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(114, 86, 227, 0.65);
  z-index: 250;
}

.hex-loading.-visible {
  opacity: 1;
}

body.-invertedbg .hex-loading {
  background-color: rgba(255, 255, 255, 0.65);
}

.hex-loading > svg {
  width: 60px;
  height: 60px;
  fill: #FFF;
}

.hex-loading > svg > path {
  transform-origin: 50% 50%;
  animation: fadein 3.3s infinite;
  opacity: 0;
  fill: rgba(255, 255, 255, 0.9);
}

body.-invertedbg .hex-loading > svg > path {
  fill: rgba(114, 86, 227, 0.9);
}

.hex-loading > svg > path:nth-child(2) {
  animation-delay: .2s;
}

.hex-loading > svg > path:nth-child(3) {
  animation-delay: .4s;
}

.hex-loading > svg > path:nth-child(4) {
  animation-delay: .6s;
}

.hex-loading > svg > path:nth-child(5) {
  animation-delay: .8s;
}

.hex-loading > svg > path:nth-child(6) {
  animation-delay: 1.0s;
}

.hex-loading > svg > path:nth-child(7) {
  animation-delay: 1.2s;
}

@keyframes fadein {
  0%,
  100% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  33%,
  66% {
    transform: scale(0.9, 0.9);
    opacity: 1;
  }
}

.hex-bg {
  position: fixed;
  z-index: -1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: opacity 1s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
}

.hex-bg.-visible {
  opacity: 1;
}

.hex-bg > .element {
  position: absolute;
  width: 300px;
  height: 330px;
  animation-name: floating-bg;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  background-image: url("../img/hex-blur.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes floating-bg {
  from {
    transform: translate(0, 0) translateZ(0);
  }
  55% {
    transform: translate(5%, 5%) translateZ(0);
  }
  to {
    transform: translate(0, 0) translateZ(0);
  }
}

.hex-bg > .element:nth-child(1) {
  animation-duration: 6s;
}

.hex-bg > .element:nth-child(2) {
  animation-duration: 5s;
}

.hex-bg > .element:nth-child(3) {
  animation-duration: 10s;
}

.hex-bg > .element:nth-child(4) {
  animation-duration: 8s;
}

.hex-bg > .element:nth-child(5) {
  animation-duration: 10s;
}

.hex-bg > .element:nth-child(6) {
  animation-duration: 7s;
}

.hex-bg > .element:nth-child(7) {
  animation-duration: 10s;
}

.hex-bg > .element:nth-child(8) {
  animation-duration: 5s;
}

.hex-bg > .element:nth-child(9) {
  animation-duration: 10s;
}

.hex-bg > .element:nth-child(10) {
  animation-duration: 6s;
}

.hex-bg > .element:nth-child(11) {
  animation-duration: 9s;
}

.hex-bg > .element:nth-child(12) {
  animation-duration: 6s;
}

.hex-bg > .element:nth-child(13) {
  animation-duration: 8s;
}

.hex-bg > .element:nth-child(14) {
  animation-duration: 7s;
}

.hex-bg > .element:nth-child(15) {
  animation-duration: 10s;
}

.hex-bg > .element:nth-child(16) {
  animation-duration: 10s;
}

.hex-bg > .element:nth-child(17) {
  animation-duration: 7s;
}

.hex-bg > .element:nth-child(18) {
  animation-duration: 6s;
}

.hex-bg > .element:nth-child(19) {
  animation-duration: 6s;
}

.hex-bg > .element:nth-child(20) {
  animation-duration: 9s;
}

.hex-bg > .element:nth-child(odd) {
  left: -100px;
}

.hex-bg > .element:nth-child(even) {
  right: -100px;
}

.hex-bg > .element:nth-child(1) {
  top: 0;
}

.hex-bg > .element:nth-child(2) {
  top: 300px;
}

.hex-bg > .element:nth-child(3) {
  top: 600px;
}

.hex-bg > .element:nth-child(4) {
  top: 900px;
}

.hex-bg > .element:nth-child(5) {
  top: 1200px;
}

.page-content {
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 0;
}

.page-content > svg g {
  transition: opacity .35s linear;
  opacity: 1;
}

.page-content > svg > g {
  transform: translateY(50px);
}

.speeches-list {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 66ch;
  min-height: 0 !important;
  padding: 20px 8vw 100px 8vw;
  transition: opacity 0.35s cubic-bezier(0.55, 0, 0.1, 1) 0.7s;
}

.speeches-list > .item {
  position: relative;
  display: flex;
  min-height: 55px;
  padding-top: 20px;
  cursor: pointer;
}

.speeches-list > .item::before {
  position: absolute;
  top: 0;
  display: block;
  width: 2px;
  height: 100%;
  margin-left: 19px;
  content: '';
  background-color: #FFF;
}

.speeches-list > .item:first-of-type::before {
  top: unset;
  bottom: 0;
  height: calc(50% - 10px);
}

.speeches-list > .item:last-of-type::before {
  height: calc(50% + 10px);
}

.speeches-list > .item > .hex {
  width: 40px;
  transform-origin: center;
  background-image: url("../img/hexagon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.speeches-list > .item > .hex.-size5 {
  transform: scale(1);
}

.speeches-list > .item > .hex.-size4 {
  transform: scale(0.8);
}

.speeches-list > .item > .hex.-size3 {
  transform: scale(0.6);
}

.speeches-list > .item > .hex.-size2 {
  transform: scale(0.4);
}

.speeches-list > .item > .hex.-size1 {
  transform: scale(0.2);
}

.speeches-list > .item > .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
}

.speeches-list > .item > .content > p {
  font-size: .75rem;
  margin-top: 2px;
}

.speeches-list > .item > .content > .timestamp {
  display: flex;
}

.speeches-list > .item > .content > .timestamp > * + * {
  margin-left: 5px;
}

.speeches-list > .item > .content > .timestamp > span {
  font-size: 1rem;
  font-weight: bold;
}

.hexagon-group {
  cursor: pointer;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes floating {
  from {
    transform: translate(0, 0) translateZ(0);
  }
  55% {
    transform: translate(0.3%, 0.3%) translateZ(0);
  }
  to {
    transform: translate(0, 0) translateZ(0);
  }
}

.hexagon-group:nth-child(1) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(2) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(3) {
  animation-duration: 8s;
}

.hexagon-group:nth-child(4) {
  animation-duration: 9s;
}

.hexagon-group:nth-child(5) {
  animation-duration: 10s;
}

.hexagon-group:nth-child(6) {
  animation-duration: 9s;
}

.hexagon-group:nth-child(7) {
  animation-duration: 10s;
}

.hexagon-group:nth-child(8) {
  animation-duration: 9s;
}

.hexagon-group:nth-child(9) {
  animation-duration: 8s;
}

.hexagon-group:nth-child(10) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(11) {
  animation-duration: 10s;
}

.hexagon-group:nth-child(12) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(13) {
  animation-duration: 6s;
}

.hexagon-group:nth-child(14) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(15) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(16) {
  animation-duration: 8s;
}

.hexagon-group:nth-child(17) {
  animation-duration: 10s;
}

.hexagon-group:nth-child(18) {
  animation-duration: 10s;
}

.hexagon-group:nth-child(19) {
  animation-duration: 7s;
}

.hexagon-group:nth-child(20) {
  animation-duration: 7s;
}

.simple-link {
  align-self: center;
  margin-top: 10px;
  padding: 20px;
  text-decoration: underline;
  color: #000;
}

.simple-link:active {
  color: #9951F5;
}

.error-message {
  display: flex;
  visibility: visible;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding-right: 8vw;
  padding-left: 8vw;
  transition: opacity .3s .15s, visibility 0s .15s, width 0s .15s, height 0s .15s;
  opacity: 1;
}

body.-invertedbg .error-message svg g {
  fill: #000;
}

.error-message.-hide {
  visibility: hidden;
  flex: 0;
  width: 0;
  max-height: 0;
  transition: opacity .3s .3s, visibility 0s 0s, width 0s 0s, height 0s 0s;
  opacity: 0;
}

.error-message.-server {
  transform: translateY(-4rem);
}

.error-message.-server.-hide {
  display: none;
}

.error-message.-server > .icon {
  width: 100%;
  max-width: 20rem;
}

.error-message.-server:not(.-hide) ~ * {
  display: none;
}

.error-message > .icon {
  width: 8rem;
}

.error-message > .message {
  line-height: 1.4;
  margin-top: 2rem;
  text-align: center;
  text-transform: uppercase;
}

body.-invertedbg .error-message > .message {
  color: #000;
}

.error-message > .message > h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.selected-bar {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 58px;
  margin-top: 10px;
}

.selected-bar > .bar {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 4px;
  background-color: currentColor;
}

.selected-bar > .bar::before, .selected-bar > .bar::after {
  position: absolute;
  top: -4px;
  width: 2px;
  height: 12px;
  content: '';
  background-color: currentColor;
}

.selected-bar > .bar::before {
  left: 0;
}

.selected-bar > .bar::after {
  right: 0;
}

.selected-bar > .bar > .label {
  font-size: 12px;
  transform: translateY(-20px);
  text-transform: uppercase;
}

.selected-bar > .back {
  font-size: 12px;
  position: relative;
  margin-top: 15px;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.selected-bar > .back::before {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  position: absolute;
  left: -3px;
  display: flex;
  content: '←';
  transform: translateX(-100%);
}

.player-controls {
  position: relative;
  z-index: 90;
  display: flex;
  justify-content: center;
  height: 36px;
  margin: 0 10vw 15px 10vw;
  transition: all 0.3s ease;
}

.player-controls.-hide {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.player-controls > button {
  position: absolute;
  display: inline-flex;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  padding: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1), visibility 0.3s, opacity 0.15s ease;
}

.player-controls > button.-hide {
  visibility: hidden;
  opacity: 0;
}

.player-controls > .play {
  background-image: url("../img/play.svg");
}

.player-controls > .stop {
  transform: translateX(-50%);
  background-image: url("../img/stop.svg");
}

.player-controls > .stop.-hide {
  transform: translateX(0);
}

.player-controls > .stop:not(.-hide) ~ .play {
  transform: translateX(50%);
}

.player-controls > .stop:not(.-hide) ~ .pause {
  transform: translateX(50%);
}

.player-controls > .stop.-movestop {
  transform: translateX(0);
}

.player-controls > .stop.-movestop ~ .play {
  transform: translateX(0);
}

.player-controls > .pause {
  transform: translateX(50%);
  background-image: url("../img/pause.svg");
}

.player-controls > .pause.-hide {
  transform: translateX(0);
}

.range-slider {
  position: absolute;
  width: 100%;
  user-select: none;
  transition: opacity 0.7s 0.5s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s 0.25s cubic-bezier(0.55, 0, 0.1, 1), color 0s cubic-bezier(0.55, 0, 0.1, 1);
}

.range-slider.-hide {
  transition: opacity 0.3s 0s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s 0s cubic-bezier(0.55, 0, 0.1, 1), color 0.3s 0s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
}

.range-player {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: calc(100% - 4px);
  height: 5px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.7s 0.5s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s 0.25s cubic-bezier(0.55, 0, 0.1, 1), color 0s cubic-bezier(0.55, 0, 0.1, 1);
}

.range-player.-hide {
  transition: opacity 0.3s 0s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s 0s cubic-bezier(0.55, 0, 0.1, 1), color 0.3s 0s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
}

.range-player::before, .range-player::after {
  position: absolute;
  top: -4px;
  width: 2px;
  height: 12px;
  content: '';
}

.range-player::before {
  right: 100%;
  background-color: #FFF;
}

.range-player::after {
  left: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.range-player > .label {
  font-size: 12px;
  transform: translate(2px, 16px);
  text-transform: uppercase;
}

.range-player > .label:first-of-type {
  margin-right: auto;
  transform: translate(-2px, 16px);
}

.range-player > .controls {
  position: absolute;
  width: calc(100% + 22px);
  padding: 4px 0;
  margin-top: -4px;
}

.range-player > .controls > .handle {
  position: absolute;
  top: -3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  box-shadow: -1px 1px 1px 0 rgba(77, 77, 77, 0.25);
}

.range-player > .controls > .handle > .currentdate {
  font-size: 12px;
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  white-space: pre-line;
  text-align: center;
  width: 4ch;
  pointer-events: none;
}

.range-player > .controls > .handle > .currentdate > span {
  opacity: 0;
  display: block;
  animation: show-label 0.2s ease forwards;
}

.range-player > .controls > .handle > .currentdate > span.-noanimation {
  animation: none;
  opacity: 1;
}

.range-player .fill {
  height: 5px;
  margin-left: 9px;
  background-color: #FFF;
}

.range-selected {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5px;
  color: #FFF;
  background-color: #FFF;
  margin-top: 45px;
}

.range-selected.-hide {
  transition: opacity 0.3s 0s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s 0s cubic-bezier(0.55, 0, 0.1, 1), color 0.3s 0s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateY(100%);
  opacity: 0;
}

body.-invertedbg .range-selected {
  color: #000;
  background-color: #000;
}

.range-selected::before, .range-selected::after {
  position: absolute;
  top: -4px;
  width: 2px;
  height: 12px;
  content: '';
  background-color: #FFF;
}

.range-selected::before {
  left: 0;
}

body.-invertedbg .range-selected::before {
  background-color: #000;
}

.range-selected::after {
  right: 0;
}

body.-invertedbg .range-selected::after {
  background-color: #000;
}

.range-selected > .label {
  font-size: 12px;
  transform: translateY(-20px);
  text-transform: uppercase;
}

.range-selected > .label:first-of-type {
  margin-right: auto;
}

.range-selected > .back {
  font-size: 12px;
  position: absolute;
  margin-top: 15px;
  text-decoration: underline;
}

.range-selected > .back::before {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  position: absolute;
  left: -3px;
  display: flex;
  content: '←';
  transform: translateX(-100%);
}

.date-filters {
  position: relative;
  height: 58px;
  margin: 0 10vw;
}

._hidden {
  visibility: hidden;
  flex: 0;
  width: 0;
  max-height: 0 !important;
  min-height: 0 !important;
  opacity: 0 !important;
}

._hidden.-active {
  transition: opacity .3s 0s, visibility 0s .3s, width 0s .3s, height 0s .3s;
}
